<template>
  <iframe id="iframe" frameborder="0" @load="getAppToken" allowfullscreen="true"
          :src="url" scrolling="yes" ref="iframe"></iframe>
</template>
<script>
import { clearLoginInfo } from '@/utils'
import {dowloadByUrl} from "../../utils/utils";
export default {
  data () {
    return {
      url: '',
      code: '',
      appId: '',
      houseId: '',
      dataId: '', // 进报修\问题应用查看详情的时候会带数据id
      dataStatus: '', // 进报修\问题应用查看详情的时候会带数据status
      subarea: '', // 进走访应用时的传参
      buildingId: '', // 进走访应用时的传参
      unitId: '', // 进走访应用时的传参
      buildingStr: '', // 进走访应用时的传参
      visitType: '', // 进走访应用时的传参
      isToInfo: null, // 是否是查看详情
      placeId: null, // 进巡查应用时的传参id
      placeType: null,// 进巡查应用时的传参type 场所企业或者店铺
      peopleType: null,
      iframeWindow: null,
      userId: null,
      userName: '',
      activityId: null,
      way: null,
      SM:'',
    }
  },
  created () {
    document.title = this.$route.query.appName
    this.url = this.$route.query.url
    if (!window.location.href.startsWith('https')) {
      this.url = this.$route.query.url.replace('https', 'http')
    }
    if (this.$route.query.appCode == 'hn_salary'){
      this.url = this.url + '?latitude=' + this.$globalData.latitude + '&longitude=' + this.$globalData.longitude
    }
    if (this.$route.query.countyFormCode) {
      // this.navVisible = false
      this.countyFormCode = this.$route.query.countyFormCode
    }
    this.appId = this.$route.query.appId
    this.code = this.$route.query.appCode
    this.houseId = this.$route.query.houseId
    this.dataId = this.$route.query.dataId
    this.dataStatus = this.$route.query.dataStatus
    this.subarea = this.$route.query.subarea
    this.buildingId = this.$route.query.buildingId
    this.unitId = this.$route.query.unitId
    this.buildingStr = this.$route.query.buildingStr
    this.userId = this.$route.query.userId
    this.userName = this.$route.query.userName || this.$globalData.userInfo.userName
    this.placeId = this.$route.query.placeId || null
    this.placeType = this.$route.query.placeType || null
    this.visitType = this.$route.query.visitType || null
    this.isToInfo = this.$route.query.isToInfo || null
    this.SM=this.$route.query.SM || null
    this.name = this.$route.query.name || null
    this.sex = this.$route.query.sex || null
    this.type = this.$route.query.type || null
    this.activityId = this.$route.query.id || null
    this.way = this.$route.query.way || null
    this.problemId=this.$route.query.problemId||null
    this.solveStatus=this.$route.query.solveStatus||null

    if (this.$route.query.peopleType) {
      this.peopleType = this.$route.query.peopleType
    }
    // this.getLocation();
    window.addEventListener('message', event => {
      if (event.data == 'close' || event.data == 401) {
        this.$router.push('/myApp')
      } else if (event.data.name == 'wxDownload') {
        wx.miniProgram.navigateTo({
          url: '/pages/web/pdfDownload?returnUrl=' + event.data.path
        })
      } else if (event.data == 'toVisit') {
        this.$router.push('/visit')
      } else if (event.data == 'toPatrol') {
        this.$router.push('/patrol')
      } else if (event.data == 'toVisitStatistics') {
        this.$router.push('/statistics-visit')
      } else if (event.data.router == 'toHouseInfo') {
        // 拆分应用跳到h5页面
        this.$store.commit('setHouseId', event.data.houseId,)
        // 保存拆分传参leaveRouter，表示是拆分的哪个页面跳转到这里的，便于在返回拆分的时候能再重新进去到那个页面
        sessionStorage.setItem('leaveRouter',event.data.leaveRouter)
        this.$router.push({path: '/house-view-details',query: {id:event.data.houseId}})
      } else if (event.data.name == 'review') {
        if(event.data.query.dire == 2){ //审核通过
          wx.miniProgram.navigateTo({
            url: '/pages/xiaoheDaily/reviewPassed?id=' + event.data.query.id
          })
        }else{
          wx.miniProgram.navigateTo({  //直接分享
            url: '/pages/xiaoheDaily/share?id=' + event.data.query.id
          })
        }
      }
      if (event.data == 'getLocation') {
        //获取经纬度
        wx.getLocation({
          type: 'wgs84',
          success: function(res) {
            debugger
            console.log(JSON.stringify(res))
            var latitude = res.latitude
            var longitude = res.longitude
          },
          fail: function(res) {
            console.log('fail' + JSON.stringify(res))
          }
        })
      }
      if(event.data.isClearSessionStorage == 1) {
        // 拆分传值告诉h5移除leaveRouter
        sessionStorage.removeItem('leaveRouter')
      }
    })
    this.andRndParam()
  },
  mounted () {
    this.iframeWindow = this.$refs.iframe.contentWindow
    // appToken验证失败时跳到登录页
    window.addEventListener('message', (event) => {
      if (event.data == 401) {
        clearLoginInfo()
        this.$message.error('token验证失败，请重新登录')
        this.$router.push({ name: 'login' })
      }
    })
  },
  methods: {
    getAppToken () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/application/appToken'),
        method: 'get',
        params: this.$http.adornParams({
          appId: this.appId
        })
      }).then(({data}) => {
        // console.log(window.__wxjs_environment, window.__wxjs_environment);
        let windowType = window.__wxjs_environment
        if (data && data.code === 0) {
          // 把appToken传给iframe
          let depth
          if(this.$globalData.userInfo.orgId === this.$globalData.userInfo.street) {
            depth = 4
          }else {
            depth = 5
          }
          this.iframeWindow.postMessage({
            SM:this.SM||'',
            token: this.$globalData.token,
            appid: this.$globalData.appid,
            domain: this.$globalData.domain2,
            appToken: data.appToken,
            appId: this.appId,
            depth,
            orgId: this.$globalData.userInfo.orgId,
            orgUserId: this.$globalData.userInfo.userId,
            street: this.$globalData.userInfo.street,
            userName: this.userName,
            mobile: this.$globalData.userInfo.mobile,
            country: this.$globalData.userInfo.country,
            communityName: this.$globalData.userInfo.communityName,
            streetName: this.$globalData.userInfo.streetName,
            windowType: windowType,
            peopleType: this.peopleType,
            wgggUrl: this.$url,
            solveStatus:this.solveStatus,
            address: this.$globalData.address,
            userType: 2,
            syncToken: this.$globalData.token,
            problemId:this.problemId,
            code: this.code,
            houseId: this.houseId,
            dataId: this.dataId,
            dataStatus: this.dataStatus,
            subarea: this.subarea,
            buildingId: this.buildingId,
            unitId: this.unitId,
            buildingStr: this.buildingStr,
            userId: this.userId,
            leaveRouter: sessionStorage.getItem('leaveRouter'),
            placeId: this.placeId,
            placeType: this.placeType,
            visitType: this.visitType,
            isToInfo: this.isToInfo,
            codeType: this.$globalData.userInfo.codeType,
            name: this.name,
            sex: this.sex,
            type: this.type,
            remindCode: this.$globalData.remindCode,
            countyFormCode: this.countyFormCode,
            activityId: this.activityId,
            way: this.way,
            latitude:this.$globalData.latitude,
            longitude:this.$globalData.longitude
          }, '*')
        } else {
          this.$message.error(data.msg)
          this.errorMsg = data.msg
        }
      })
    },
    getLocation(){
      let that = this
      // let url = decodeURIComponent(this.$globalData.ssoUrl)
      let url = encodeURIComponent(this.$globalData.ssoUrl)
      let appid = this.$globalData.appid

      this.$globalData.appid = 'wx618f83db4e422ea9'
      // 先获取签名信息,先执行wx.config ,再获取经纬度
      this.$http({
        url: this.$http.adornUrl('/wxapp/getSign'),
        method: 'get',
        params: this.$http.adornParams({
          url: url
        })
      }).then(({data}) => {
        wx.config({
          debug: true,
          appId: 'wx618f83db4e422ea9',
          timestamp: data.sign.timestamp,
          nonceStr: data.sign.nonceStr,
          signature: data.sign.signature,
          jsApiList: ['getLocation']
        })

        this.$globalData.appid = appid

        wx.ready(function () {
          alert('成功')
          console.log('成功1')
          //获取经纬度
          wx.getLocation({
            type: 'wgs84',
            // success: that.success
            success: function(res) {
              debugger
              alert('信息：'+ JSON.stringify(res))
              console.log('经纬度'+JSON.stringify(res))
              this.latitude = res.latitude
              this.longitude = res.longitude
            }
            // fail: function(res) {
            //   console.log('fail' + JSON.stringify(res))
            // }
          })
        })

        wx.error(function(res){
          alert('失败：' +JSON.stringify(res))
          console.log("错误信息:"+JSON.stringify(res))
        });
      })

    },
    success(res){
      debugger
      console.log('经纬度'+JSON.stringify(res))
      this.latitude = res.latitude
      this.longitude = res.longitude
    },
    /**
     * 添加时间戳参数去除缓存
     */
    andRndParam () {
      if (this.url.indexOf('t=') === -1) {
        if (this.url.indexOf('?') === -1) {
          this.url += '?t=' + new Date().getTime()
        } else {
          this.url += '&t=' + new Date().getTime()
        }
      }
    }
  }
}
</script>
<style scoped>
iframe {
  display: block; /* iframes are inline by default */
  border: none; /* Reset default border */
  height: 100vh !important; /* Viewport-relative units */
  width: 100vw !important;
}
</style>
